import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Update Email User Details by ID
const cus_userService_UpdateUserEmailByID = (data) =>
  axios.put(
    domainAPI + '/api/user/' + data.id,
    {
      email: data.email,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update Password User Details by ID
const cus_userService_UpdateUserPasswordByID = (data) =>
  axios.put(
    domainAPI + '/api/user/' + data.id,
    {
      current_password: data.current_password,
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update Profile Registration
const cus_userService_UpdateUserProfile = (data) =>
  axios.put(
    domainAPI + '/api/user-profile/' + data.id,
    {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      tel: data.tel,
      company_name: data.company_name,
      gender: data.gender,
      birth_date: data.birth_date,
      prefectures: data.prefectures,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Questionnaire Registration
const cus_userService_UpdateUserQuestionnaire = (data) =>
axios.put(
  domainAPI + '/api/customer/questionnaire/' + data.id,
  {
    client_attributes: data.client_attributes,
    floor_plan_type: data.floor_plan_type,
    building_type: data.building_type,
    managed_property_numbers: data.managed_property_numbers,
  },
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
);

  

export {
  cus_userService_UpdateUserEmailByID,
  cus_userService_UpdateUserPasswordByID,
  cus_userService_UpdateUserProfile,
  cus_userService_UpdateUserQuestionnaire,
};
